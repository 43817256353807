<template>
  <div class="role-management">
    <div class="search-top">
      <el-button type="primary" size="mini" @click="goToAdd">新增</el-button>
    </div>
    <div style="clear: both"></div>
    <el-table
      :data="tableData"
      style="width: 100%"
      class="mt20r"
      stripe
      border
      :height="tableHeight"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="roleName" label="优惠条件" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="roleDesc"
        label="优惠金额"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="roleDesc" label="有效期" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-slot="scope">
        <div class="option-list-box">
          <el-link
            :underline="false"
            type="primary"
            @click="goToEdit(scope.row)"
            >编辑</el-link
          >

          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="getTableDataList"
        :current-page.sync="listQuery.current"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑角色信息弹窗  -->
    <el-dialog
      title="新增优惠项目"
      :visible.sync="editRoleDialogVisible"
      width="530px"
      center
    >
      <el-form label-position="left" label-width="80px" :model="form">
        <el-form-item label="优惠条件">
          <el-input
            v-model="form.region"
            placeholder="请输入订单满额，单位元"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠金额">
          <el-input
            v-model="form.type"
            placeholder="请输入优惠金额，单位元"
          ></el-input>
        </el-form-item>
        <el-form-item label="有效期">
          <el-date-picker
            v-model="searchCondition_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions0"
            @change="searchConditionDateChage"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      listQuery: {
        current: 1,
        size: 10,
      },
      searchCondition_date: [], //有效期
      total: 0,
      tableData: [],
      isAdd: true, //编辑类型 true 新增 false 修改
      editRoleDialogVisible: false, //编辑界面弹窗是否显示
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    searchConditionDateChage(val) {
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
    handleSizeChange(val) {
      this.listQuery.size = val;
      this.listQuery.current = 1;
      this.getTableData();
    },
    // 新增
    goToAdd() {
      this.isAdd = true;
      this.editRoleDialogVisible = true;
    },
    // 编辑
    goToEdit(row) {
      this.isAdd = false;
      this.editRoleDialogVisible = true;
    },
    //查询列表
    getTableDataList(val) {
      this.listQuery.current = val;
      this.getTableData();
    },
    //确定编辑事件
    confirmEditHandle() {},
    //获取数据
    getTableData() {},
    deleteOption(row) {
      this.$confirm("确定要删除此用户？", "删除用户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除");
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
.role-management {
  .search-top {
    height: 40px;
    float: right;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
}
</style>